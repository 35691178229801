<template>
  <div :style="getStyle" class="data-table-view">
    <el-row align="middle">
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        class="bottom-space"
        :style="computedStyles"
      >
      <span style="display: flex;">
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label for="text">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span
          v-if="isActive || isDataTableField"
          class="setting-icon"
          @click="openSettings"
        >
          <i class="el-icon-s-tools" />
        </span>
      </el-col>
    </el-row>

    <div
      v-if="data.table_data_view_type == 'table_view'"
      class="table-container"
    >
      <table style="width: 100%" class="fixed-table">
        <tr>
          <th
            v-if="!data.data_table_columns || !data.data_table_columns.length"
          >
            <div class="instruction-text">
              <i>Build & configure table by dragging fields here..</i>
            </div>
          </th>

          <template
            v-if="data.data_table_columns && data.data_table_columns.length"
          >
            <th
              v-for="(column, index) in data.data_table_columns"
              :key="index"
              :style="
              column.input_type === 'CHECKBOX_GROUP'
                ? `width: ${column.width - 150}px; white-space: normal; word-wrap: break-word;`
                : column.input_type === 'RADIO_BUTTON_GROUP'
                ? `width: ${column.width + 100}px; white-space: normal; word-wrap: break-word;`
                : `width: ${column.width}px;`
            ">
              <span @click="(e) => checkInner(e, column, index)">
                {{ column.label }}
              </span>
            </th>
          </template>
        </tr>
        <tr>
          <template
            v-if="data.data_table_columns && data.data_table_columns.length"
          >
          <td
            v-for="(column, index) in data.data_table_columns"
            :key="index"
            :style="column.input_type === 'CHECKBOX_GROUP' || column.input_type === 'RADIO_BUTTON_GROUP' ? `width: ${column.width-100}px; white-space: normal; word-wrap: break-word;` : `width: ${column.width}px;`"
            >
              <span @click="(e) => checkInner(e, column, index)">
                <component
                  v-bind:is="column.components.view"
                  :data="column"
                  :isInDataTable="true"
                  :isDataTableField="true"
                  v-on:settings="
                    openSettingsForDataTable($event, column, index)
                  "
                ></component>
              </span>
            </td>
          </template>
        </tr>
      </table>
    </div>
    <div
      v-else-if="data.table_data_view_type == 'card_view'"
      class="table-container"
    >
      <el-scrollbar class="scrollbar">
        <div style="width: 100%" class="fixed-table">
          <div
            style="
              display: flex;
              justify-content: space-evenly;
              flex-wrap: wrap;
              border: 1px dotted grey;
              min-height: 100px;
            "
          >
            <div
              v-if="!data.data_table_columns || !data.data_table_columns.length"
            >
              <span>
                <i>configure Card fields by draging into the card</i></span
              >
            </div>
            <div v-else class="single-card">
              <div
                v-for="(column, index) in data.data_table_columns"
                :key="index"
                :style="{ width: column.width + 'px' }"
              >
                <span @click="(e) => checkInner(e, column, index)">
                  <component
                    v-bind:is="column.components.view"
                    :data="column"
                    :isDataTableField="true"
                    v-on:settings="
                      openSettingsForDataTable($event, column, index)
                    "
                  ></component>
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div v-else class="data-table-view">
      <el-collapse style="width: 100%" accordion>
        <el-collapse-item
          v-if="!data.data_table_columns || !data.data_table_columns.length"
        >
          <template slot="title">
            <i
              >Build & configure accordion by dragging fields here, Double click
              to edit existing</i
            >
          </template>
        </el-collapse-item>
        <el-collapse-item
          v-if="data.data_table_columns && data.data_table_columns.length"
        >
          <template slot="title">
            <i
              >Build & configure accordion by dragging fields here, Double click
              to edit existing</i
            >
          </template>
          <div
            v-for="(column, index) in data.data_table_columns"
            :key="index"
            :style="`width:${column.width}px;`"
          >
            <p>{{ column.label }}</p>
            <span @click="(e) => checkInner(e, column, index)">
              <component
                v-bind:is="column.components.view"
                :data="column"
                :isInDataTable="true"
                :isDataTableField="true"
                v-on:settings="openSettingsForDataTable($event, column, index)"
              ></component>
            </span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-if="data.properties.enable_add_button" class="instruction-button">
      <el-button class="p-1" size="mini" icon="el-icon-plus" type="primary">
        Add Row</el-button
      >
    </div>
  </div>
</template>

<script>
import { dataTableAllowedViewFields } from "./index";
import { mapGetters } from "vuex";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "DataTableView",
  props: ["field", "data", "isActive", "fieldsData", "isDataTableField","isIconRequired"],
  components: {
    ...dataTableAllowedViewFields,
  },
  data() {
    return {
      activeNames: "1",
      showLabel: false,
      aggregateFunctionsBasedOnTable: [],
    };
  },
  mounted() {
    this.data.showLabel = false;
    this.aggregateFunctionsBasedOnTable = this.fieldsData.filter((field) => {
      if (field.fields_type == "TABLE_FIELDS") {
        let selectedField = field.selected_fields.find((e) => {
          let index = this.data.data_table_columns.findIndex(
            (field) => field.key == e.key
          );

          if (index > -1) {
            // this.data.data_table_columns[index].aggregate_function = field;
            return true;
          }

          return false;
        });
        return selectedField ? true : false;
      }
    });

    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    computedStyles() {
    const styles = {};
    styles.display = 'flex';
    if (this.data?.styles?.labelAlignments) {
      styles.justifyContent = this.data.styles.labelAlignments;
    }
    return styles;
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }
      return inputStyle + this.getElementStyle;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      return borderStyle;
    },
  },
  methods: {
    checkInner(e, column, index) {
      if (!e) e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$emit("configureColumn", { column, index, tableIndex: 0 });
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type == "HTML_CONTENT") {
          // this.isList = true;
          this.data.content = globalVariable.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
    // tableWith()
    // {
    //   if()
    //   {
    //     return 'min-width:500px;'
    //   }
    //   else{
    //     return 'width:100%;'
    //   }
    // },
    openSettingsForDataTable(e, column, index) {
      if (!e) e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$emit("settingsDataTableColumn", { column, index, tableIndex: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.resposive-font {
  font-size: 1.2vw;
}

.data-table-view {
  max-width: 1180px;
  .el-collapse-item__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 48px;
    line-height: 48px;
    background-color: slategray;
    border-radius: 5px;
    padding: 0px 0px 0px 10px;
    color: white;
    cursor: pointer;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    font-weight: 500;
    -webkit-transition: border-bottom-color 0.3s;
    transition: border-bottom-color 0.3s;
    outline: 0;
  }

  .el-collapse-item__header.is-active {
    border-bottom-color: transparent;
    background-color: var(--lighter-primary-color) !important;
    color: var(--primary-contrast-color) !important;
  }

  .el-collapse-item__content {
    padding-bottom: 25px;
    font-size: 13px;
    color: #303133;
    background-color: #ffffff !important;
    line-height: 1.769230769230769;
    padding: 20px;
    margin: 10px;
    width: 92%;
    border-radius: 5px;
    box-shadow: 0 0px 10px 5px rgb(0 0 0 / 13%) !important;
  }

  .el-collapse-item__arrow.is-active {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: var(--lighter-primary-color);
  }
}
</style>

<style scoped>
table tr {
  vertical-align: top;
}

table,
th,
td {
  border: 1px solid var(--lighter-primary-color);
  background-color: white;
  width: 100px;
  height: 50px;
  line-height: normal;
}

tr.noBorder td th {
  border: 0;
}
</style>

<style scoped>
.table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto; /* Add a horizontal scrollbar if content overflows */
  scrollbar-width: thin;
  border-radius: 15px;
  box-shadow: 0 0px 10px 3px rgba(27, 72, 126, 0.1294117647);
}

.scrollbar {
  /* Set a fixed width for the scrollbar container */
  width: 100%;
}

.fixed-table {
  width: 100%; /* Make the table take up the full width of the container */
  table-layout: fixed; /* Fix the table layout */
  border-collapse: collapse; /* Collapse table borders */
}

/* Define a fixed width for each column */
.fixed-table th,
.fixed-table td {
  padding: 0px 2px; /* Adjust padding as needed */
  vertical-align: middle;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Show ellipsis for long text */
}

.fixed-table tr,
.fixed-table th,
.fixed-table td {
  line-height: 1; /* Adjust the line-height value to decrease row height */
}

.single-card {
  border: 2px black solid;
}

th {
  text-align: left;
  /* Center align text horizontally */
  padding: 8px;
  background-color: var(--lighter-primary-color);
  /* Default background color for table heading */
  white-space: nowrap;
  /* Prevent line breaks in table headings */
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-contrast-color);
  /* Add ellipsis (...) for long headings */
}

.instruction-text {
  display: flex;
  justify-content: center;
}

.instruction-button {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}
</style>
